/* Ionic Variables and Theming. */
/* This is just a placeholder file For more info, please see: */
/* https://ionicframework.com/docs/theming/basics */

/* To quickly generate your own theme, check out the color generator */
/* https://ionicframework.com/docs/theming/color-generator */

:root {
  --ion-color-warning: #FFCC57;
  --ion-color-warning-rgb: 255, 204, 87;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e6b74e;    
  --ion-color-warning-tint: #ffd766; 
}
