/* You can add global styles to this file, and also import other style files */

@import '~bootstrap/dist/css/bootstrap.min.css';

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.drag-scroll-wrapper.drag-scroll-container .drag-scroll-content {
    overflow: auto !important;
}

.mat-form-field .mat-form-field-wrapper .mat-form-field-flex {
    background-color: transparent;
    border: 1px solid #97979B;
    border-radius: 7px;
    height: auto;
    font-size: 14px;
    padding: .35em .75em 0 .75em;
}

.user-login-button-container {
    margin-right: 10px;
}

.user-login-button {
    padding: 5px;
    font-size: 14px;
    background-color: #e58d00;
    border-radius: 10px;
    border: none;
    color: white;
    margin-right: 10px;
}

.user-signup-button {
    padding: 5px;
    font-size: 14px;
    background-color: #FFFFFF;
    border-radius: 10px;
    border: 1px solid #E55D00;
    color: #E55D00;
    margin-right: 10px;
}

.language-select {
    display: flex;
    align-items: center;
    /* color: #E55D00; */
}

.fullpage {
    background: #FFFFFF 0% 0% no-repeat padding-box;
}

.mat-form-field.mat-focused .mat-form-field-label {
    color: #97979B;
}

button:focus {
    outline: none;
}

.header .language-input {
    width: 40px;
    margin-right: 15px;
}

.mat-select-placeholder {
    color: rgba(0, 0, 0, 0.4);
}
.language-input.mat-form-field .mat-form-field-wrapper .mat-form-field-flex {
    background-color: transparent;
    border: 1px solid transparent;
    height: 20px;
    font-size: 12px;
    color: #FFCC57;
}

.mat-select-panel-wrap .ng-trigger.ng-trigger-transformPanel.mat-select-panel.mat-primary {
    min-width: 20px !important;
}

.mat-option-text {
    color: #553e00 !important;
}

.mat-select-value-text {
    color: #553e00;
}

.mat-card-text {
    width: auto;
    height: auto;
    text-align: left;
    font: normal normal bold 12px/18px Poppins;
    letter-spacing: 0.12px;
    color: #E58D00;
    margin-bottom: 4px;
  }

.logoName {
  margin-left: 15px;
  height: 37px;
  text-align: left;
  font: normal normal bold 16px/25px Poppins;
  letter-spacing: 0px;
  color: #553e00;
}

.menu-button {
  color: #e58d00;
  background: #FFF4D6 0% 0% no-repeat padding-box;
  border: 1px solid #00000000;
  border-radius: 0px 25px 25px 0px;
  height: 55px;
  width: 55px;
  margin-top: 11px;
  padding: 10px;
  margin-left: -1px;
}

.range-text {
    margin-left: 6px;
    font-family: 'Poppins';
    font-size: 16px;
    color: #553E00;
  }

  ion-range {
    --bar-background: #F4F3F0;
    --bar-background-active: #FFCC57;
    --bar-height: 12px;
    --bar-border-radius: 8px;
    --knob-background: #FFCC57;
    --knob-size: 40px;
    --pin-background: #FFCC57;
    --pin-color: #fff;
  }

  .w-700 {
    font-weight: 700;
  }
  
  .mt-80 {
    margin-top: 80px;
  }

  .mat-form-field-appearance-fill .mat-form-field-infix {
    padding: 0 0 1.1em 0;
  }

  .router-link {
    font-size: 16px;
    font-weight: 600;
    color: #2B2B3C;
    padding: 0 40px;
    text-align: center;
  }

  .modal-wrapper.sc-ion-modal-md {
    height: 80%;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    position: absolute;
    bottom: 0;
  }

  .swiper-pagination-fraction {
    top: 170px;
    left: 325px;
    /* left: 285px; */
    width: 70px;
    height: 22px;
    padding: 1px;
    border: 1px solid #CFD1D5;
    border-radius: 5px;
  }

  .btn-text {
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
  }

  ion-segment ion-label {
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
  }

  .selected-grey {
    color: #444D5E;
  }

  .grey {
    color: #B1B3B7;
  }